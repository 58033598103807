import * as React from "react";
import { Box } from "rebass";
import Img from "gatsby-image";
import ReactPlayer from "react-player";
import { Button, Divider } from "antd";
import styled from "styled-components";

import { Title, Para } from "../basic";
import { colors } from "../../theme";
import { Link } from "gatsby";

interface Props {
  title: string;
  video: string;
  name: string;
  tagline: string;
  cover?: {
    childImageSharp: {
      fluid: any;
    };
  };
}

const VideoWrapper: React.FC<Props> = ({ title, video, name, tagline, cover }) => {
  const [isVideoStarted, updateIsVideoStarted] = React.useState(false);

  return (
    <StyledBox
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#cacaca",
      }}
    >
      <div
        className="overlay"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.5)",
          display: isVideoStarted ? "none" : "block",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            textAlign: "center",
            width: "80%",
            pointerEvents: "none",
          }}
        >
          <Title style={{ color: colors.background }}>{name}</Title>
          <Divider />
          <Para style={{ color: colors.background, marginBottom: "1.6rem" }}>
            {tagline}
          </Para>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          left: "50%",
          bottom: 2,
          transform: "translateX(-50%)",
          zIndex: 200,
        }}
      >
        <Link to={`/p/${title}`}>
          <Button>View Casestudy</Button>
        </Link>
      </div>
      <ReactPlayer
        url={video}
        // loop
        light={true}
        width="100%"
        height="100%"
        playing
        muted
        controls
        config={{
          youtube: {
            playerVars: { rel: 0 },
          },
        }}
        playIcon={
          <Button
            shape="circle"
            icon="caret-right"
            size="large"
            style={{ marginLeft: 15, zIndex: 1000 }}
            onClick={() => updateIsVideoStarted(true)}
          />
        }
        onPlay={() => {
          updateIsVideoStarted(true);
        }}
      />
      {cover && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: isVideoStarted ? "none" : "block",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <Img
            fluid={cover.childImageSharp.fluid}
            style={{ height: "100%" }}
            imgStyle={{ objectFit: "cover" }}
          />
        </div>
      )}
    </StyledBox>
  );
};

export default VideoWrapper;

const StyledBox = styled(Box)`
  position: relative;
  & > .overlay {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 100;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover > .overlay {
    opacity: 1;
  }
`;

/*
.player-wrapper {
  position: relative;
  padding-top: 56.25% // Player ratio: 100 / (1280 / 720) 
}
 
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
*/
