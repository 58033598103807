import * as React from "react";
import { Link } from "gatsby";
import { Flex, Box } from "rebass";
// @ts-ignore
import Fade from "react-reveal/Fade";

import { Section, FocusText, Title, Para, PrimaryButton } from "../basic";
import PatternBackground from "../PatternBackground";
import VideoWrapper from "../casestudy/VideoWrapper";

interface Props {
  focusText?: string;
  title: string;
  subtitle?: string;
  buttonText?: string;
  projects?: Array<{
    frontmatter: {
      video: string;
      title: string;
      name: string;
      tagline: string;
      tech: Array<string>;
      color: Array<string>;
    };
  }>;
  hideViewAllButton?: boolean;
}

const PortfolioSection: React.FC<Props> = ({
  focusText,
  title,
  subtitle,
  buttonText = "View All",
  projects,
  hideViewAllButton,
}) => {
  return (
    <PatternBackground patternType={4}>
      <Section>
        <Flex
          flexDirection="column"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <Fade bottom>
            <FocusText>{focusText}</FocusText>
          </Fade>
          <Fade bottom>
            <Title>{title}</Title>
          </Fade>
          <Fade bottom delay={200}>
            <Para>{subtitle}</Para>
          </Fade>
          <Flex flexDirection={["column", "column", "row"]} flexWrap="wrap">
            {projects.map(({ frontmatter }, i) => (
              <Box
                key={i}
                width={[1, 1, i === 0 ? 1 : 1 / 2]}
                pl={[0, 0, i === 0 ? 0 : i % 2 === 0 ? 3 : 0]}
                pr={[0, 0, i === 0 ? 0 : i % 2 !== 0 ? 3 : 0]}
              >
                <Fade bottom delay={400}>
                  <Box
                    mb={5}
                    style={{
                      width: "100%",
                      height: i === 0 ? "600px" : "400px",
                      backgroundColor: "#cacaca",
                    }}
                  >
                    <VideoWrapper {...frontmatter} />
                  </Box>
                </Fade>
              </Box>
            ))}
          </Flex>
          {!hideViewAllButton && (
            <Fade bottom delay={400}>
              <Box style={{ textAlign: "center" }}>
                <Link to="/portfolio">
                  <PrimaryButton>{buttonText}</PrimaryButton>
                </Link>
              </Box>
            </Fade>
          )}
        </Flex>
      </Section>
    </PatternBackground>
  );
};

export default PortfolioSection;
