import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PortfolioSection from "../components/index/PortfolioSection";

export default (props: any) => {
  const data = props.data.allFile.edges[0].node.childPageJson;

  const pageSchemaContent = {
    name: "Projects",
    "@type": "ItemList",
    itemListElement: data.projects.map((e: any, index: number) => ({
      "@type": "ListItem",
      position: index + 1,
      url: `https://imchetanyadav.com/p/${e.frontmatter.title}`,
    })),
  };

  return (
    <Layout>
      <SEO
        pageTitle="Projects"
        pageDescription="Projects done by @imchetanyadav"
        pageSchemaContent={pageSchemaContent}
        showOnlyPageSchema
        pageKeywords={[`projects`]}
      />
      <PortfolioSection {...data} hideViewAllButton />
    </Layout>
  );
};

export const portfolioQuery = graphql`
  query {
    allFile(
      filter: {
        name: { eq: "portfolio" }
        sourceInstanceName: { eq: "content" }
      }
    ) {
      edges {
        node {
          childPageJson {
            focusText
            title
            subtitle
            projects {
              frontmatter {
                cover {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                video
                title
                name
                tagline
                tech
                color
              }
            }
          }
        }
      }
    }
  }
`;
